import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero } from "../components/hero"
import PageCTA from "../components/pageCTA"

export const query = graphql`
  query ProjectsPageQuery {
    file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allContentfulProjectGallery {
      nodes {
        entryTitle
        projects {
          references {
            projectName
            projectSlug
            projectDescription {
              raw
            }
            projectImages {
              gatsbyImageData
              description
            }
          }
        }
      }
    }
  }
`
const ProjectsPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData
  const projects = data.allContentfulProjectGallery.nodes[0].projects.references

  return (
    <Layout>
      <Seo title="Bostonian Electric Projects" />
      <Hero
        image={banner}
        header="Bostonian Electric Projects"
        alt="Electrician working on wires"
      />
      <section className="container my-6 mx-auto px-4 font-sans">
        <h2>Projects</h2>
        <p>Check out our latest projects.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {projects.map(project => (
            <div className="p-4 shadow" key={project.projectSlug}>
              <Link to={project.projectSlug}>
                <h3>{project.projectName}</h3>
                <GatsbyImage image={project.projectImages[0].gatsbyImageData} alt={project.projectImages[0].description} />
              </Link>
            </div>
          ))}
        </div>
      </section>

      <PageCTA
        h2="Ready to learn more?"
        buttonText="Get a quote"
        destination="/contact/"
      >
        <p>
          Please{" "}
          <Link
            className="text-yellow-400 hover:underline"
            to="/contact/"
            title="contact"
          >
            contact us
          </Link>{" "}
          for a quote.
        </p>
      </PageCTA>
    </Layout>
  )
}
export default ProjectsPage
