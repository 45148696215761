import * as React from "react"
import { navigate } from "gatsby"

const PageCTA = ({ h2, children, buttonText, destination}) => (
  <section className="container my-4 mx-auto px-4 font-sans">
    <div className="flex flex-col">
      <div className="my-6">
        <hr/>
      </div>
      <div className="flex flex-col md:flex-row space-y-4">
        <div className="flex-grow space-y-4">
          <h2 className="text-4xl">{h2}</h2>
          {children}
        </div>
        <div className="flex-grow">
          <div className="flex justify-center">
            <button
              className="text-yellow-400 hover:text-white bg-transparent hover:bg-yellow-400 border border-yellow-400 px-6 py-4 rounded-full uppercase"
              onClick={() => {navigate(`${destination}`)}}
              title={buttonText}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default PageCTA